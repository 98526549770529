<template>
  <div id="page-categorias">
    <b-list-group>
      <div>
        <h2>Gerenciar Categorias</h2>

        <!-- <div class="form-row  bv-example-row-flex-cols"> -->
        <!-- <b-container class="bv-example-row"> -->

            <b-row align-h="between">
                
                <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAdd" >Cadastrar</b-button>

                <div class="form-group col-lg-4">
                    <label><span class="text-danger"></span> Buscar</label>
                    <input class="form-control"  v-model="filtro" />
                </div>

            </b-row>

        <!-- </b-container> -->
        <!-- </div> -->
      </div>

      <div class="tabela">
        <b-table
          id="my-table"
          striped
          hover
          class="text-left"
          ref="selectableTable"
          :items="filtrar"
          :fields="fields"
          :busy="load"
          small
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong></strong>
            </div>
          </template>


          <template #cell(Itens)="data">
              <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->                      
              <b-icon id="icon" class="icones" icon="folder-plus" scale="1.5" variant="info" @click="showModalGerenciarItens(data.item)"></b-icon>                    
          </template>  
          <template #cell(Editar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/edit1.png"
              alt="editar"
              height="23px"
              @click="Editar(data.item)"
            />
          </template>
          <template #cell(Apagar)="data">
            <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
            <img
              src="../assets/del4.png"
              alt="apagar"
              height="23px"
              @click="showModalApagar(data.item)"
            />
          </template>
        </b-table>
      </div>
    </b-list-group>


    

    <!-- MODAL CATEGORIA -> ADD -->
    <div>
      <b-modal ref="modal-add" hide-footer title="Categoria" size="lg">
        <div class="row text-left col-lg-12">
          
          <div class="form-group col-lg-12">
            <label><span class="text-danger">*</span> Nome da categoria</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="form.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

        </div>

        <b-row align-h="around">
          <b-button class="mt-3 botao-confirmar" variant="success" @click="apagar">Confirmar</b-button>
          <b-button class="mt-3 botao-confirmar" variant="danger" @click="hideModalAdd">Cancelar</b-button>
        </b-row>

      </b-modal>
    </div>
    <!-- MODAL CATEGORIA -> ADD -->

    <!-- MODAL CATEGORIA -> DEL-->
    <div>
      <b-modal ref="modal-del" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a remoção?</h3>
        </div>

        <b-row align-h="around">
          <b-button class="mt-3 botao-confirmar" variant="success" @click="apagar">Sim</b-button>
          <b-button class="mt-3 botao-confirmar" variant="danger" @click="hideModalApagar">Não</b-button>
        </b-row>

      </b-modal>
    </div>
    <!-- MODAL CATEGORIA -> DEL-->












    <!-- MODAL GERENCIAR ITENS -->
    <div class="modal-itens">
      <b-modal ref="modal-gerenciar-itens" hide-footer title="Gerenciar Itens" size="lg">
        <h3>Categoria: {{categoria}}</h3>
          <b-list-group>
            <div>

              <!-- <div class="form-row  bv-example-row-flex-cols"> -->
              <!-- <b-container class="bv-example-row"> -->

                  <b-row align-h="between">
                      
                      <b-button class="btnRight" variant="btn btn-info btn-sg" @click="showModalAddItem" >Cadastrar</b-button>

                      <div class="form-group col-lg-4">
                          <label><span class="text-danger"></span> Buscar</label>
                          <input class="form-control"  v-model="filtroItens" />
                      </div>

                  </b-row>

              <!-- </b-container> -->
              <!-- </div> -->
            </div>

            <div class="tabelaaa">
              <b-table
                id="my-table"
                striped
                hover
                class="text-left"
                ref="selectableTable"
                :items="filtrarItens"
                :fields="fieldsItens"
                :busy="loadItens"
                small
                outlined
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong></strong>
                  </div>
                </template>


                <template #cell(Editar)="data">
                  <!-- <img src="../../assets/edit1.png" alt="editar" height="25px"  @click="row.toggleDetails"> -->
                  <img
                    src="../assets/edit1.png"
                    alt="editar"
                    height="23px"
                    @click="EditarItem(data.item)"
                  />
                </template>
                <template #cell(Apagar)="data">
                  <img
                    src="../assets/del4.png"
                    alt="apagar"
                    height="23px"
                    @click="showModalApagarItem(data.item)"
                  />
                </template>
              </b-table>
            </div>
          </b-list-group>
 
      </b-modal>
    </div>
    <!-- MODAL GERENCIAR ITENS -->




    <!-- MODAL ITENS -> ADD -->
    <div>
      <b-modal ref="modal-add-item" hide-footer title="Itens" size="lg">
        <div class="row text-left col-lg-12">
          
          <div class="form-group col-lg-12">
            <label><span class="text-danger">*</span> Nome do item</label>
            <b-form-input
              id="input-1"
              class="text-uppercase"
              v-model="formItem.nome"
              onkeyup="return lowerCase(this)"
            ></b-form-input>
          </div>

        </div>

        <b-row align-h="around">
          <b-button class="mt-3 botao-confirmar" variant="success" @click="saveItem">Confirmar</b-button>
          <b-button class="mt-3 botao-confirmar" variant="danger" @click="hideModalAddItem">Cancelar</b-button>
        </b-row>

      </b-modal>
    </div>
    <!-- MODAL ITENS -> ADD -->

    <!-- MODAL ITENS -> DEL-->
    <div>
      <b-modal ref="modal-del-item" hide-footer title="">
        <div class="d-block text-center">
          <h3>Você confirma a remoção?</h3>
        </div>

        <b-row align-h="around">
          <b-button class="mt-3 botao-confirmar" variant="success" @click="apagarItem">Sim</b-button>
          <b-button class="mt-3 botao-confirmar" variant="danger" @click="hideModalApagarItem">Não</b-button>
        </b-row>

      </b-modal>
    </div>
    <!-- MODAL ITENS -> DEL-->

  </div>
</template>

<script>
import api from "../services/api";
import { msg, acessoRestrito } from "@/global";

export default {
  name: "Categorias",

  data() {
    return {
      dadosHeader: {},
      filtro: '',
      filtroItens: '',
      categoria: '',

      form: {},
      formItem: {},
      categorias: [],
      itensCategorias: [],

      fields: [
        { key: "nome", label: "Categoria" },
        { key: "countItens", label: "Quatidade Itens" },
        "Itens",
        "Editar",
        "Apagar",
      ],

      fieldsItens: [
        { key: "nome", label: "Item" },
        { key: "Editar", label: "Editar", thClass: 'text-right', tdClass: 'text-right' },
        { key: "Apagar", label: "Apagar", thClass: 'text-center', tdClass: 'text-center' },
      ],

      items: [], 
      itemsLista: [], 

      load: true,
      loadItens: false,
      perPage: 10,
      currentPage: 1,
    };
  },

  created() {
    this.getCategorias();
  },

  methods: {

    showModalApagar(item) {
      acessoRestrito(1030)
      this.$refs["modal-del"].show();
      this.form = item;
    },
    hideModalApagar() {
      this.$refs["modal-del"].hide();
    },

    showModalAdd() {
      acessoRestrito(1031)
      this.form = {}
      this.$refs["modal-add"].show()
    },
    hideModalAdd() {
      this.$refs["modal-add"].hide();
    },



    showModalGerenciarItens(item) {
      this.idCategoria = item.id
      this.categoria = item.nome.toLowerCase()
      this.$refs["modal-gerenciar-itens"].show();
      this.getItensCategorias()
    },

    showModalApagarItem(item) {
      acessoRestrito(1043)
      this.$refs["modal-del-item"].show();
      this.formItem = item;
    },
    hideModalApagarItem() {
      this.$refs["modal-del-item"].hide();
    },

    showModalAddItem() {
      acessoRestrito(1041)
      this.formItem = {}
      this.$refs["modal-add-item"].show();
    },
    hideModalAddItem() {
      this.$refs["modal-add-item"].hide();
    },



    getCategorias() {
      acessoRestrito(1030)
      this.load = true;

      const idSede = this.$store.state.sede.id 

      api
        .get(`categorias/${idSede}`)
        .then((res) => {
          this.load = false;
          this.items = res.data;
        })
        .catch((err) => {
          this.load = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    getItensCategorias() {
      acessoRestrito(1040)
      this.loadItens = true;

      api
        .get(`categorias/itens/${this.idCategoria}`)
        .then((res) => {
          this.loadItens = false;
          this.itemsLista = res.data;
        })
        .catch((err) => {
          this.loadItens = false;
          msg(err.response.data, "vermelho", 5000);
        });
    },

    apagar() {
      acessoRestrito(1034)
      this.$refs["modal-del"].hide();

      api
        .delete(`categoria/${this.form.id}`)
        .then(() => {
          msg("CATEGORIA REMOVIDA COM SUCESSO.", "verde", 3000);
          this.getCategorias();
        })
        .catch((err) => msg(err.response.data, "vermelho", 3000));
    },

    apagarItem() {
      acessoRestrito(1043)
      this.$refs["modal-del-item"].hide();

      api
        .delete(`categoria/item/${this.formItem.id}`)
        .then(() => {
          msg("ITEM REMOVIDO COM SUCESSO.", "verde", 3000);
          this.getItensCategorias();
        })
        .catch((err) => msg(err.response.data, "vermelho", 3000));
    },

    Editar(item) {
      acessoRestrito(1032)
      this.form = item;
      this.$refs["modal-add"].show()
    },

    EditarItem(item) {
      acessoRestrito(1042)
      this.formItem = item;
      this.$refs["modal-add-item"].show();
    },

    save() {

      this.form.idSede = this.$store.state.sede.id

      api
        .post("categoria", this.form)
        .then((res) => {
          this.hideModalAdd();
          msg(res.data, "verde", 5000);
          this.getCategorias();
          this.form = {};
        })
        .catch((err) => {
          msg(err.response.data, "vermelho", 5000);
        });
    },

    saveItem() {
      this.formItem.idCategoria = this.idCategoria
      api
        .post("categoria/item", this.formItem)
        .then((res) => {
          this.hideModalAddItem();
          msg(res.data, "verde", 5000);
          this.getItensCategorias();
        })
        .catch((err) => {
          msg(err.response.data, "vermelho", 5000);
        });
    },

  },

    computed: {


        filtrar() {

            // console.log('opaa')
            
            if (this.filtro.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
                if(item.nome.toLowerCase().match(this.filtro)) listaFiltrada.push(item) 
                if(item.countItens && item.countItens.toString().match(this.filtro)) listaFiltrada.push(item) 
            })

            return listaFiltrada
        },

        filtrarItens() {

            // console.log('opaa')
            
            if (this.filtroItens.trim() == '') return this.itemsLista
                
            let listaFiltrada = []
            this.itemsLista.forEach((item) => {
                if(item.nome.toLowerCase().match(this.filtroItens)) listaFiltrada.push(item) 
                if(item.countItens && item.countItens.toString().match(this.filtroItens)) listaFiltrada.push(item) 
            })

            return listaFiltrada
        },

    },

  
};
</script>

<style scoped>
/* #id {
        margin: 30px;
    } */

.btnRight {
  /* float: right; */
  height: 40px;
  width: 140px;
  margin-top: 33px;
  margin-bottom: 10px;
  margin-left: 15px;
  /* font-size: 18px; */
  font-weight: bold;
}

#page-categorias h2 {
  margin-top: 50px;
  margin-bottom: 15px;
  color: rgb(70, 81, 82);
}

.tabela {
  width: 80vw;
}

img {
  cursor: pointer;
}

#icon {
  cursor: pointer;
}

span {
  margin-top: 30px;
}

table.b-table[aria-busy="true"] {
  opacity: 0.6;
}

.botao-confirmar {
  width: 45%; 
  font-size: 18px;
}

@media (max-width: 700px) {

    h2 {
        margin-left: 15px;
        font-size: 22px;
        font-weight: bold;
    }


    .tabela {
        margin-left: 15px;
        width: 310px;
        overflow: auto;
    }

    .btnRight {
        height: 40px;
        width: 90%;
        margin-top: 33px;
        margin-left: 15px;
        font-weight: bold;
    }

}


</style>