<template> 
<div>  
  
  
  <div class="row div-border">

    <div class="form-group col-lg-6">
      <label><span class="text-danger"></span> Nome</label>
      <b-form-input
        id="input-1"
        class="text-uppercase"
        v-model="form.nome"
      ></b-form-input>
    </div>
  
    <div class="form-group col-lg-6">
      <label><span class="text-danger"></span> CPF</label>
      <b-form-input
        id="input-1"
        class="text-uppercase"
        v-model="form.cpf"
        v-mask="'###.###.###-##'"
        maxlength="14"
      ></b-form-input>
    </div>

    <div class="form-group col-lg-12">
      <label><span class="text-danger"></span> Referente</label>
      <b-form-input
        id="input-1"
        class="text-uppercase"
        v-model="form.referente"
        maxlength="50"
      ></b-form-input>
    </div>

  </div>
    

  <!-- <b-card
    class="mt-3"
      header="Resultado">
    <pre class="m-0">{{ hospede }}</pre> 
  </b-card> -->

      

  <b-container class="bv-example-row">
    <b-row align-h="start">
      <b-button class="mt-3 botao-confirmar2" variant="success" @click="cadastrarRecibo()">Confirmar</b-button>
      <b-button class="mt-3 botao-confirmar2" variant="danger" @click="cancelar()">Cancelar</b-button>
    </b-row>
  </b-container>


</div> 
</template>

<script>
import api from "@/services/api";
// import dayjs from "dayjs";

export default {
name: 'ModalInformeRecibo', 
props: {
  lancamento: {}
},

data() {
  return {

    load: false,

    form: {
      idLancamento: null,
      valor: null,
      numero: null,
      nome: null,
      cpf: null,
      referente: null
    }

  }
},

created() { 
  // console.log(this.lancamento)

  this.form.idLancamento = this.lancamento.id
  this.form.valor = this.lancamento.valor
  this.gerarCodigo()
},

methods: {

  msgNotification(texto, tipo, tempo) {
    this.$toast.open({
      message: texto,
      type: tipo,
      position: 'top-right',
      duration: tempo
      // all of other options may go here
    })
  },


  gerarCodigo() {


    api.get(`lancamento/cod-recibo/${this.lancamento.idSede}`)
    .then((res) => {

      // console.log(res.data)
      const codRecibo = res.data 
      this.form.numero = codRecibo
      // this.msgNotification('Baixa confirmada.'+codRecibo, 'success', 4000)

    })
    .catch((err) => {
      this.msgNotification(err.response.data, 'warning', 5000)
    })

  },


  cadastrarRecibo() {
    
    const lancamento = {
      ...this.lancamento,
      reciboNome: this.form.nome.toUpperCase(),
      reciboCpf: this.form.cpf,
      reciboNumero: this.form.numero,
      reciboReferente: this.form.referente.toUpperCase()
    }
    // console.log('lancamento', lancamento)
    
    api.put("lancamento/dados-recibo", this.form)
    .then(() => {

      // this.msgNotification('Recibo atualizado.', 'success', 4000)
      this.$emit('EMIT_InformeRecibo_confirmar', lancamento)

    })
    .catch((err) => {
      this.msgNotification(err.response.data, 'warning', 5000)
    })

  },

  cancelar() {
    this.$emit('EMIT_fechar_modal')
  },

}

  
};

</script>

  
<style scoped>



.div-border {
  margin: 1px;
}



.botao-confirmar2 {
  width: 170px; 
  margin-left: 10px;
  margin-bottom: 15px;
}

.cursor { 
  cursor: pointer;
}

.linha {
  width: 100%;
  height: 1px;
  background-color: #17a2b8;
}

</style>