import { render, staticRenderFns } from "./ImprimirRecibo.vue?vue&type=template&id=a3f46352&scoped=true"
import script from "./ImprimirRecibo.vue?vue&type=script&lang=js"
export * from "./ImprimirRecibo.vue?vue&type=script&lang=js"
import style0 from "./ImprimirRecibo.vue?vue&type=style&index=0&id=a3f46352&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f46352",
  null
  
)

export default component.exports