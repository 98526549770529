import api from "@/services/api";
import { acessoRestrito, capitalizeText } from "@/global";
import dayjs from 'dayjs'
import ModalFiltroAvancado from './modal/ModalFiltroAvancado'
import ModalImprimir from './modal/ModalImprimir'
import ImprimirRecibo from './modal/ImprimirRecibo'
import ModalInformeRecibo from './modal/ModalInformeRecibo'


export default {
  name: "Pagadores",
  components: { 
    ModalFiltroAvancado,
    ModalImprimir,
    ImprimirRecibo,
    ModalInformeRecibo
   },

  data() {
    return {
      dadosHeader: {},
      filtro: "",


      corTipoLancamento: 'warning',
      fornecedorOuPagador: '',
      showButtonIdentSocio: false,
      
      contas: [],
      fornecedores: [],
      fornecedoresListaCompleta: [],
      pagadores: [],
      pagadoresListaCompleta: [],
      categorias: [],
      itensCategorias: [],

      selectedMes: null,
      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],

      selectedAno: null,


      fields: [
        { key: "Tipo", label: "", sortable: true },
        { key: "seq", label: "Cód.", sortable: true },
        { key: "fornecedor", label: "Fornecedor/Pagador", sortable: true },
        { key: "documento", label: "Nº Documento", sortable: true },
        { key: "vencimento", label: "Vencimento", sortable: true, formatter: value => { return dayjs(value).format('DD/MM/YYYY') }},
        { key: "baixaData", label: "Baixa", sortable: true,  formatter: value => { if (value != null) return dayjs(value).format('DD/MM/YYYY') }},
        { key: "categoria", label: "Categoria", sortable: true },
        { key: "valor", label: "Valor", sortable: true },
        { key: 'gerarRecibo', label: 'Recibo', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'Editar', label: 'Editar', thClass: 'text-center', tdClass: 'text-center' },
        { key: 'Apagar', label: 'Apagar', thClass: 'text-center', tdClass: 'text-center' },
        // "Editar",
        // "Apagar",
      ],

      formPagtos: [
        "Dinheiro",
        "Boleto",
        "Crédito",
        "Cheque",
        "Cartão Débito",
        "Cartão Crédito",
        "Débito Automático",
        "Depósito",
        "Pagamento online",
        "Tarifa",
        "Transferência",
        "DOC",
        "TED",
        "Pix"
      ],

      listaConfirmarBaixa: ['Agora','Depois'],
      labelDocumento: 'Nº Documento',

      form: {
        // confirmarBaixa: 'agora',
        tipoLancamento: 'Selecione',
        idCategoria: null,
        idFornecedor: null,
        formaPagto: 'Dinheiro',
        baixaData: null,
        fornecedoresOuPagadores: null
      },

      items: [], 
      itemsOriginal: [], 
      saldo: 'R$ 0,00',

      tituloModalMovimentos: 'Movimentos',
      movimentos: [],
      fieldsMovimentos: [
        {key: 'sel', label: '', formatter: 'sel'},
        {key: 'lancamento', label: 'Tipo'},
        {key: 'vencimento', label: 'Vencimento', formatter: 'vencimento'},
        {key: 'valor', label: 'Valor', formatter: value => {
            if (!value) return '0,00'
            
            const valores = parseFloat(value).toFixed(2)
            return valores.split('.').join(',')
        }},
        {key: 'recebValor', label: 'Valor recebido', formatter: value => {
            if (!value) return ''
            
            const valores = parseFloat(value).toFixed(2)
            return valores.split('.').join(',')
        }},
        {key: 'recebData', label: 'Data recebido', formatter: 'recebData'},
        {key: 'boleto', boleto: 'Boleto', formatter: 'boleto'},
        {key: 'boletoEmissao', label: 'Boleto emissão', formatter: 'boletoEmissao'}
      ],
      cods: [],
      camposLeitura: false,

      lancamento: {},

      loadMovimentos: true,
      load: true,
      perPage: 10,
      currentPage: 1,

      valorAntesDeEditar: null,
      dataBaixaVisivel: false,

      tipoCampoNumeroDocumento: 'padrao',

      dadosRecibo: {}

    };
  },

  created() {
    
    this.selectedMes = dayjs().format('MM')
    this.selectedAno = Number(dayjs().format('YYYY'))

    // const periodo = this.selectedAno+'-'+this.selectedMes+'-05'
    this.getListas()
    this.getLancamentos();
  },

  methods: {

    msgNotification(texto, tipo, tempo) {
      this.$toast.open({
        message: texto,
        type: tipo,
        position: 'top-right',
        duration: tempo
        // all of other options may go here
      })
    },

    showModalImprimir() {
      this.$refs["modal-imprimir"].show()
    },

    showModalFiltroAvancado() {
      this.$refs["modal-filtro-avancado"].show()
    },

    EMIT_filtrarLancamentos(lancamentos) {
      // console.log(lancamentos)
      
      // this.load = true
      this.items = lancamentos
      this.itemsOriginal = lancamentos
      this.$refs["modal-filtro-avancado"].hide()
      // this.load = false
    },

    showModalApagar(item) {
      acessoRestrito(1063)
      this.$refs["modal-del"].show();
      this.form = item;
    },
    hideModalApagar() {
      this.$refs["modal-del"].hide();
    },

    showModalAdd(acaoDB) {
      acaoDB == 'insert' ? acessoRestrito(1061) : acessoRestrito(1062) 

      
      if (acaoDB == 'insert') {
        this.corTipoLancamento = 'warning'
        this.fornecedorOuPagador = ''
        this.camposLeitura = false
        this.showButtonIdentSocio = false
        
        this.form = {
          tipoLancamento: 'Selecione',
          idCategoria: null,
          idFornecedor: null,
          formaPagto: 'Dinheiro',
          vencimento: dayjs().format('YYYY-MM-DD'),
          baixaData: null,
          codsMovimentos: []
        }
      } else {
        this.valorAntesDeEditar = this.form.valor

        const categoria = this.categorias.filter(item => item.value == this.form.idCategoria)[0]
        categoria.text == 'MENSALIDADE / AUXÍLIO' || categoria.text == 'INTERMÉDICA' 
        ? this.showButtonIdentSocio = true 
        : this.showButtonIdentSocio = false
        
      } 
      this.$refs["modal-add"].show();
      this.getSaldo()
    },
    hideModalAdd() {
      this.$refs["modal-add"].hide();
    },

    selTipoLanc(el) {
      this.form.tipoLancamento = el.target.text
      if (el.target.text == 'Despesa') {
        this.corTipoLancamento = 'danger'
        this.fornecedorOuPagador = 'Fornecedor'
        this.fornecedoresOuPagadores = this.fornecedores
      } else {
        this.corTipoLancamento = 'success'
        this.fornecedorOuPagador = 'Pagador'
        this.fornecedoresOuPagadores = this.pagadores
      }

    },

    getListas() {

      const idSede = this.$store.state.sede.id 

      api
        .get(`lancamentos/listas/${idSede}`)
        .then(res => {
          this.contas = res.data.contas;
          this.categorias = res.data.categorias;
          this.itensCategoriasTodos = res.data.itens;

          this.pagadoresListaCompleta = res.data.pagadores;
          this.fornecedoresListaCompleta = res.data.fornecedores;

          this.pagadores = res.data.pagadores.map(item => {
            return {
              value: item.id,
              text: item.nome
            }
          })
          this.fornecedores = res.data.fornecedores.map(item => {
            return {
              value: item.id,
              text: item.nome
            }
          })

        })
        .catch((err) => {
          this.msgNotification(err.response.data, 'error', 5000)
        });
    },

    getLancamentos() {
      acessoRestrito(1060) 
      this.load = true;

      // const idSede = this.$store.state.sede.id 
      const idConta = this.$store.state.conta.id
      const periodo = this.selectedAno+'-'+this.selectedMes+'-05'
      const filtro = this.filtro == '' ? 'vazia' : this.filtro 
      
      api.get(`lancamentos/${idConta}/${periodo}/${filtro}`)
      .then((res) => {
          // console.log('aaaaaaaaaaaaaaaa2222222222222222')
          this.load = false
          this.items = res.data
          this.itemsOriginal = res.data
        })
        .catch((err) => {
          this.load = false;
          this.msgNotification(err.response.data, 'error', 5000)
        });
    },


    buscarLancamentos() {

      this.selectedMes = dayjs().format('MM')
      this.selectedAno = Number(dayjs().format('YYYY'))

      this.getLancamentos();
    },


    getSaldo() {

      
      const conta = this.$store.state.conta
      // console.log('ooooooooooooooooooooooo',conta.id)
      api.get('lancamento/saldo/'+conta.id)
      .then(res => {
        this.saldo = 'Saldo disponivel: R$' + res.data
        // console.log('res.data',res.data)
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },


    fitrarDB() {

      
      const conta = this.$store.state.conta
      // console.log('ooooooooooooooooooooooo',conta.id)
      api.get('lancamento/saldo/'+conta.id)
      .then(res => {
        this.saldo = 'Saldo disponivel: R$' + res.data
        // console.log('res.data',res.data)
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      });
    },

    // filtrar() {

    //   const busca = this.filtro.toString().toLowerCase()
    //   // console.log(busca,'lllpppppppppppppppppp')

    //   if (busca.trim() == '') 
    //   this.items = this.itemsOriginal
          
    //   const listaFiltrada = []
    //   this.itemsOriginal.forEach((item) => {
    //       if(item.seq.toString().match(busca)) listaFiltrada.push(item) 
    //       if(item.fornecedor.toLowerCase().match(busca)) listaFiltrada.push(item) 
    //       if(item.documento && item.documento.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
    //       if(item.vencimento.toLowerCase().toString().match(busca)) listaFiltrada.push(item) 
    //       if(item.baixaData && item.baixaData.toLowerCase().toString().match(busca)) listaFiltrada.push(item) 
    //       if(item.categoria.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
    //       if(item.valor.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
    //   })

    //   this.items = [...new Set(listaFiltrada)]
    // },

    


    getCategorias() {
      this.load = true;

      api
        .get(`pagadores/categorias`)
        .then((res) => {
          this.load = false;
          this.categorias = res.data;
        })
        .catch((err) => {
          this.load = false;
          this.msgNotification(err.response.data, 'error', 5000)
        });
    },

    getItensCategorias(idCategoria) {
      this.camposLeitura = false
      // this.form.idCategoria = null
      this.form.valor = null

      const categoria = this.categorias.filter(item => item.value == idCategoria)
      if (categoria[0].text == 'MENSALIDADE / AUXÍLIO' || categoria[0].text == 'INTERMÉDICA') {

        // const pagador = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)
        // console.log('pagador',pagador)
        this.form.identSocio = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].cnpjCpfRsRi 

        this.showButtonIdentSocio = true
        this.showModalDocumento() 

        // Usado para mudar campo de nº documento para nº recibo
        this.form.formaPagto == 'Pix' 
        ? this.tipoCampoNumeroDocumento = 'pix'
        : this.tipoCampoNumeroDocumento = 'recibo'
        

      } else {
        this.showButtonIdentSocio = false
        this.tipoCampoNumeroDocumento = 'padrao'

        // Apaga dados recibo
        if (!this.form.id) {
          this.form.documento = null 
          this.form.reciboNumero = null 
          this.form.reciboNome = null 
          this.form.reciboCpf = null 
          this.form.reciboRsRi = null 
          this.form.reciboReferente = null 
        }
      }


      // Gera lista de itens para a categoria selecionada.
      //---------------------------------------------------
      this.itensCategorias = this.itensCategoriasTodos
      .filter(item => item.idCategoria == idCategoria)
      .map(item => {
        return {
          value: item.id,
          text: item.nome
        }
      })
      //---------------------------------------------------
    },

    changeFormaPagamento(pagamento) {
      // console.log('ppp',pagamento)

      const categoria = this.categorias.filter(item => item.value == this.form.idCategoria)
      if (categoria[0].text == 'MENSALIDADE / AUXÍLIO' || categoria[0].text == 'INTERMÉDICA') {

        // this.form.documento = null // Regra antes da geração de recibo automatico

        // Usado para mudar campo de nº documento para nº recibo
        pagamento == 'Pix' 
        ? this.tipoCampoNumeroDocumento = 'pix'
        : this.tipoCampoNumeroDocumento = 'recibo'
        
      } else {
        this.tipoCampoNumeroDocumento = 'padrao'
      }

    },


    formatMoeda(event) {
      
      // console.log(event.target.value)
      let input = event.target

      var valor = input.value.replace(/,/, '')

      if (valor.length > 2)
          input.value = valor.substring(0, valor.length - 2) +','+ valor.substring(valor.length - 2, valor.length)
      else   
      if (valor.length > 0 && valor.length < 4)
          input.value = valor
      else   
          input.value = ''

    },

    trataDataBaixa(evento) {
      // console.log(evento)
      if (evento == 'Agora') {
        this.form.baixaData = dayjs(this.form.vencimento).format('YYYY-MM-DD')
        this.dataBaixaVisivel = true
      } else {
        this.form.baixaData = null
        this.dataBaixaVisivel = false
      } 

    },

    apagar() {
      acessoRestrito(1063) 
      this.$refs["modal-del"].hide();

      api.delete(`lancamentos/${this.form.id}`)
      .then(() => {
        this.msgNotification('LANÇAMENTO REMOVIDO.', 'success', 3000)
        this.getLancamentos();
      })
      .catch((err) => this.msgNotification(err.response.data, 'error', 5000))
      
    },

    Editar(item) {
      acessoRestrito(1062) 

      this.form = item
      this.cods = item.codsMovimentos

      const categoria = this.categorias.filter(item => item.value == this.form.idCategoria)[0]
      if (categoria.text == 'MENSALIDADE / AUXÍLIO' || categoria.text == 'INTERMÉDICA') {
        this.camposLeitura = true 
        this.showButtonIdentSocio = true
      } else {
        this.camposLeitura = false
        this.showButtonIdentSocio = false
      }




      if (item.tipoLancamento == 'Despesa') {
        this.corTipoLancamento = 'danger'
        this.fornecedorOuPagador = 'Fornecedor'
        this.fornecedoresOuPagadores = this.fornecedores
      } else {
        this.corTipoLancamento = 'success'
        this.fornecedorOuPagador = 'Pagador'
        this.fornecedoresOuPagadores = this.pagadores
      }

      this.showModalAdd();
    },


    getDadosFornecedorOuPagador() {

      if (this.form.tipoLancamento == 'Despesa') {

        this.form.idCategoria = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].idCategoria
        // this.form.documento = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].cnpjOuCpf
        this.form.idItem = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].idItemCategoria
        this.form.formaPagto = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].formaPagto
        
        const diaVencimento = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].diaVencimento 
        const dataPrecadastro = diaVencimento ? dayjs().format('YYYY-MM-') + diaVencimento : dayjs().format('YYYY-MM-DD')
        this.form.vencimento = dayjs(dataPrecadastro).format('YYYY-MM-DD')
        
        const valor = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].valor
        const valor1 = parseFloat(valor).toFixed(2)
        this.form.valor = isNaN(valor1) ? '0,00' : valor1.toString().split('.').join(',') 

      } else {
          
        this.form.idCategoria = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].idCategoria
        // this.form.documento = this.fornecedoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].cnpjOuCpf
        this.form.idItem = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].idItemCategoria
        this.form.formaPagto = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].formaPagto
        
        const diaVencimento = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].diaVencimento 
        const dataPrecadastro = diaVencimento ? dayjs().format('YYYY-MM-') + diaVencimento : dayjs().format('YYYY-MM-DD')
        this.form.vencimento = dayjs(dataPrecadastro).format('YYYY-MM-DD')
        
        const valor = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].valor
        const valor1 = parseFloat(valor).toFixed(2)
        this.form.valor = isNaN(valor1) ? '0,00' : valor1.toString().split('.').join(',') 


        const categoria = this.categorias.filter(item => item.value == this.form.idCategoria)
        if (categoria[0].text == 'MENSALIDADE / AUXÍLIO' || categoria[0].text == 'INTERMÉDICA') {

          this.form.identSocio = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0].cnpjCpfRsRi

          this.showButtonIdentSocio = true
          this.showModalDocumento() 

        } else {
          this.showButtonIdentSocio = false
        }

      }

    },


    toFornecedorPagador() {

      if (this.fornecedorOuPagador == '') {
        this.msgNotification('Seleciona se é fornecedor ou pagador', 'warning', 3000)
        return
      }
     
      if (this.fornecedorOuPagador == 'Fornecedor') {
        this.$router.push({ name: 'Fornecedores' }) 
      } else {
        this.$router.push({ name: 'Pagadores' })
      }

    },

    toCategorias() {

      this.$router.push({ name: 'Categorias' })
    },


    confirmarDocumento() {
      if (!this.form.identSocio || this.form.identSocio.trim() == '') {
        this.msgNotification('Informe a identificação do sócio.', 'warning', 5000)
        return
      }

      this.$refs["modal-documento"].hide();
      this.getMovimentos()   
      this.showModalMovimentos()   
    },

    showModalDocumento() {
      this.$refs["modal-documento"].show();
    },
    hideModalDocumento() {
      this.$refs["modal-documento"].hide();
      this.form.idCategoria = null
      this.form.valor = null
      this.showButtonIdentSocio = false
    },

    getMovimentos() {

      // console.log('ooooooooooo',this.form.codsMovimentos)

      // if (this.movimentos.length > 0) return
      this.loadMovimentos = true
      this.tituloModalMovimentos = 'Movimentos'

      api
        .get(`lancamentos-movimentos/socios/movimentos/${this.form.identSocio}`)
        .then(res => {
          this.movimentos = res.data
          this.loadMovimentos = false

          // Seleciona os movimentos que foram salvos anteriomente no DB.
          this.movimentos.forEach(mov => {
            const achou = this.form.codsMovimentos.find(item => item == mov.cod)
            if (achou) mov.sel = true 
          })

          if (this.movimentos.length == 0) {
            this.hideModalMovimentos()  
            this.msgNotification('Não foi encontrado nenhum movimento com este número de documento.', 'warning', 8000)
          } else {
            this.tituloModalMovimentos = 'Movimentos - '+this.movimentos[0].nome
          }

        })
        .catch((err) => {
          this.msgNotification(err.response.data, 'error', 5000)
          this.loadMovimentos = true
        });
    },

    showModalMovimentos() {
      this.$refs["modal-movimentos"].show();
    },
    hideModalMovimentos() {
      this.$refs["modal-movimentos"].hide();
      this.form.idCategoria = null
      this.form.valor = null
      this.showButtonIdentSocio = false
    },

    confirmarMovimentos() {

      const movSelecionados = this.movimentos.filter(item => item.sel)
      
      if (movSelecionados.length == 0) {
        this.msgNotification('Informe ao menos um movimento para prosseguir.', 'warning', 5000)
        return  
      }

      this.camposLeitura = false
      
      let valorFinal = movSelecionados.reduce((a, b) => a + b.valor, 0)
      this.form.valor = valorFinal.toFixed(2).toString().split('.').join(',')

      // Gerar dados do recibo
      //-----------------------------------------------------
      this.form.documento = movSelecionados[0].cod.toString()
      this.form.reciboNumero = movSelecionados[0].cod.toString()
      this.form.reciboRsRi = movSelecionados[0].rs ?? movSelecionados[0].ri

      const pagador = this.pagadoresListaCompleta.filter(item => item.id == this.form.idFornecedor)[0]
      this.form.reciboNome = pagador.nome
      this.form.reciboCpf = pagador.cnpjCpfRsRi
      
      
      this.form.reciboReferente = '\n'
      movSelecionados.forEach(item => {

        const tipo = capitalizeText(item.lancamento.trim())
        let mes = item.mesRef.trim()
        mes = mes.substring(4, 7) + '/' + mes.substring(0, 4)
        this.form.reciboReferente += `\n${tipo} - ${mes} - Vencimento: ${item.vencimento} - R$ ${item.valor}`  
      })
      //-----------------------------------------------------

      this.camposLeitura = true
      this.$refs["modal-movimentos"].hide(); 
    },





    fecharModalBaixa() {
      this.$refs["modal-baixa"].hide();
    },

    validarBaixa(lancamento) {
      // console.log(lancamento)

      if (lancamento.confirmarBaixa == 'Agora') {
        this.msgNotification('Lançamento já possui baixa.', 'warning', 3000)
        return  
      }

      this.lancamento.id = lancamento.id
      
      this.$refs["modal-baixa"].show();
    },

    confirmarBaixa() {

      this.lancamento.dataBaixa = this.dataBaixa
      // console.log('aqui', this.lancamento)
     
      api.put("lancamento/confirmar-baixa", this.lancamento)
      .then(() => {

        this.$refs["modal-baixa"].hide();
        this.msgNotification('Baixa confirmada.', 'success', 4000)
        this.getLancamentos();

      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'error', 5000)
      })

    },


    save() {

      this.form.idSede = this.$store.state.sede.id
      this.form.idConta = this.$store.state.conta.id

      if (this.movimentos.length > 0) {
        this.form.codsMovimentos = this.movimentos
                                    .filter(item => item.sel)
                                    .map(item => item.cod)
      }

      if (this.form.idCategoria) 
      this.form.categoria = this.categorias.filter(item => item.value == this.form.idCategoria)[0].text

      if (this.form.tipoLancamento == 'Despesa') {
        if (this.form.idFornecedor) 
        this.form.fornecedor = this.fornecedores.filter(item => item.value == this.form.idFornecedor)[0].text
      } else {
        if (this.form.idFornecedor) 
        this.form.fornecedor = this.pagadores.filter(item => item.value == this.form.idFornecedor)[0].text
      }



      api.post("lancamentos", this.form)
      .then((res) => {
        this.hideModalAdd();
        this.msgNotification(res.data, 'success', 5000)

        // Só cai nessa condição se for mensalidade e não for update
        if (!this.form.id && this.form.reciboNome) 
        this.geraRecibo(this.form)

        this.getLancamentos();
        
        this.corTipoLancamento = 'warning'
        this.form = {}
        this.form.tipoLancamento = 'Selecione'
      })
      .catch((err) => {
        this.msgNotification(err.response.data, 'warning', 5000)
        // this.form.valor = this.valorAntesDeEditar
      });
    },




    // Recibo
    //===================================================================================
    EMIT_InformeRecibo_confirmar(lancamento) {

      // console.log('lancamento-volta', lancamento)
      this.$refs["modal-preencher-recibo"].hide()
      this.geraRecibo(lancamento)
    },



    async geraRecibo(lancamento) {


      if (!lancamento.reciboNome) {

        this.form = {...lancamento}

        this.$refs["modal-preencher-recibo"].show()
        return
      }
      
      const dados = {
        reciboNumero: lancamento.reciboNumero,
        nome: lancamento.reciboNome,
        reciboCpf: lancamento.reciboCpf,
        reciboRsRi: lancamento.reciboRsRi,
        valor: lancamento.valor,
        referente: lancamento.reciboReferente
      }
      // console.log('dados',dados)



      this.dadosRecibo = dados


      this.$refs["modal-aguarde"].show()
      await new Promise(r => setTimeout(r, 1000))
      this.$refs["modal-aguarde"].hide()
    }
    //===================================================================================



  },

    computed: {

        filtrar() {

          // if (this.filtro.length > 0 && this.filtro.length < 4) return this.items

          const busca = this.filtro.toString().toLowerCase()

            if (busca.trim() == '') return this.items
                
            let listaFiltrada = []
            this.items.forEach((item) => {
              if(item.seq.toString().match(busca)) listaFiltrada.push(item) 
              if(item.fornecedor.toLowerCase().match(busca)) listaFiltrada.push(item) 
              if(item.documento && item.documento.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
              if(item.vencimento.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
              if(item.baixaData && item.baixaData.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
              if(item.categoria.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
              if(item.valor.toString().toLowerCase().match(busca)) listaFiltrada.push(item) 
            })

            return [...new Set(listaFiltrada)]
        },
    },

  
};