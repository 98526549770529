<template>
  <div>


    <div class="text-center">
      <b-spinner variant="warning" label="Spinning"></b-spinner>
      <b-spinner variant="warning" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="danger" label="Spinning"></b-spinner>
      <b-spinner variant="danger" type="grow" label="Spinning"></b-spinner>
      <b-spinner variant="info" label="Spinning"></b-spinner>
      <b-spinner variant="info" type="grow" label="Spinning"></b-spinner>
      <!-- <b-spinner variant="primary" label="Spinning"></b-spinner>
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
      <b-spinner variant="success" label="Spinning"></b-spinner>
      <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
    </div>
  

    <!-- <div class="tabelaProdutos" v-show="false">
        <b-table id="tabelaProdutos" striped hover :items="dados.produtos" :fields="fields"></b-table>
    </div> -->



  </div>
</template>

<script>
// import {jsPDF} from 'jspdf'
// import 'jspdf-autotable'
// import "jspdf-barcode";
// import dayjs from 'dayjs';
import dayjs from 'dayjs';
import extenso from 'extenso'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'
import "jspdf-barcode";

export default {
  name: "ImprimirRecibo",
  props: {
    dados: {}
  },
  

  data() {
    return {

      load: false,

      user: {},

      valorExtenso: null,
      valor: null,
      dataHoje: null,
      porcentagem: null,
      userName: null,


      meses: [
          { value: '01', text: 'Janeiro' },
          { value: '02', text: 'Fevereiro' },
          { value: '03', text: 'Março' },
          { value: '04', text: 'Abril' },
          { value: '05', text: 'Maio' },
          { value: '06', text: 'Junho' },
          { value: '07', text: 'Julho' },
          { value: '08', text: 'Agosto' },
          { value: '09', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
      ],


    }
  },


  async created() {

    const authLocal = localStorage.getItem('user')
    this.user = JSON.parse(authLocal)
    this.userName = this.user.nome



    const dia = dayjs().format('DD')
    const mes = dayjs().format('MM')
    const mesTexto = this.meses.filter(item => item.value == mes)[0].text
    const ano = dayjs().format('YYYY')
    this.dataHoje = `${this.user.sede ?? 'São Paulo'}, ${dia} de ${mesTexto} de ${ano}`
    

    const valorRecibo = this.dados.valor
    // this.valor = valorRecibo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    this.valorExtenso = extenso(parseFloat(valorRecibo.toString().split('.').join(',')), { mode: 'currency', currency: { type: 'BRL' } })


    await new Promise(r => setTimeout(r, 1000))
    this.gerarPdf()
  },

  

  methods: {

    getImgUrl(nomeFile) {
        var images = require.context('../../../assets/', false, /\.png$/)
        return images('./' + nomeFile + ".png")
    },


    async gerarPdf() {

      
      var doc = jsPDF('p', 'mm', [210, 297]);
      // var doc = jsPDF('l', 'mm', [210, 297]);

  

      // Topo
      //------------------------------------------------------------------
      var img = new Image()
      img.src = this.getImgUrl('logoSind') 
      doc.addImage(img, 'png', 9, 8, 25, 25) 

      const width = doc.internal.pageSize.getWidth()
      // const height = doc.internal.pageSize.getHeight()

      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text('Reconhecido pelo MTPS sob nº 300.304/73 em 22-03-74', width/2, 26, { align: 'center' })
      
      doc.setFontSize(8).setFont(undefined, 'bold'); 
      doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 31, { align: 'center' })

      doc.setDrawColor(0, 0, 0);
      doc.line(0, 34, 500, 34);
      //------------------------------------------------------------------



      // Corpo
      //------------------------------------------------------------------
      doc.setFontSize(15);               
      doc.text('RECIBO', width/2, 46, { align: 'center' })
      doc.text(this.dados.reciboNumero, 10, 46, { align: 'left' })
      // doc.text('265855', width/2, 56, { align: 'center'})

      // doc.setFontSize(12);          
      doc.text(`R$ ${this.dados.valor}`, 195, 46, {maxWidth: 25, align: 'right'})
      
      doc.setFontSize(10);          
      if (this.dados.reciboRsRi)
      doc.text('RS/RI: '+this.dados.reciboRsRi, 195, 51, { align: 'right'})

      doc.text(`RECEBI DE ${this.dados.nome},  PORTADOR DO CPF: ${this.dados.reciboCpf}`, 14, 67)
      // doc.text('CPF: 329.207.778-82', 166, 80, {maxWidth: 40, align: 'justify'});

      doc.text(`A IMPORTANCIA DE (${this.valorExtenso.toUpperCase()})`, 14, 74)
      doc.text(`REFERÊNTE AO PAGAMENTO DE ${this.dados.referente}`, 14, 82)


      doc.text(this.dataHoje, 28, 133)
      doc.text('_______________________________________', 110, 133)
      doc.text('Nome e Assinatura do Recebedor', 150, 137, {maxWidth: 60, align: 'center'})
      //------------------------------------------------------------------
                              



      // Traço dividor 2 VIA
      //------------------------------------------------------------------
      let traco = '-'
      for (let i=0; i < 139; i++) {
        traco += '-'
      }
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text(traco, 15, 150, { align: 'left' })
      //------------------------------------------------------------------


      


      // Topo 2 VIA
      //------------------------------------------------------------------
      const pos = 150
      doc.addImage(img, 'png', 9, 8+pos, 25, 25) 


      doc.setFontSize(17).setFont(undefined, 'bold'); 
      doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14+pos, { align: 'center' })
      doc.text('Ferroviarias da Zona Sorocabana', width/2, 20, { align: 'center' })
      
      doc.setFontSize(11).setFont(undefined, 'bold'); 
      doc.text('Reconhecido pelo MTPS sob nº 300.304/73 em 22-03-74', width/2, 26+pos, { align: 'center' })
      
      doc.setFontSize(8).setFont(undefined, 'bold'); 
      doc.text('Rua Erasmo Braga, 307, 3º andar, - Presidente Altino - Osasco - SP - CEP:06213-000 - Telefone: (11) 3682-9303', width/2, 31+pos, { align: 'center' })

      doc.setDrawColor(0, 0, 0);
      doc.line(0, 34+pos, 500, 34+pos);
      //------------------------------------------------------------------



      // Corpo
      //------------------------------------------------------------------
      doc.setFontSize(15);               
      doc.text('RECIBO', width/2, 46+pos, { align: 'center' })
      doc.text(this.dados.reciboNumero, 10, 46+pos, { align: 'left' })
      // doc.text('265855', width/2, 56, { align: 'center'})

      // doc.setFontSize(12);          
      doc.text(`R$ ${this.dados.valor}`, 195, 46+pos, {maxWidth: 25, align: 'right'})
      
      doc.setFontSize(10);          
      if (this.dados.reciboRsRi)
      doc.text('RS/RI: '+this.dados.reciboRsRi, 195, 51+pos, { align: 'right'})

      doc.text(`RECEBI DE ${this.dados.nome},  PORTADOR DO CPF: ${this.dados.reciboCpf}`, 14, 67+pos)
      // doc.text('CPF: 329.207.778-82', 166, 80, {maxWidth: 40, align: 'justify'});

      doc.text(`A IMPORTANCIA DE (${this.valorExtenso.toUpperCase()})`, 14, 74+pos)
      doc.text(`REFERÊNTE AO PAGAMENTO DE ${this.dados.referente}`, 14, 82+pos)


      doc.text(this.dataHoje, 28, 133+pos)
      doc.text('_______________________________________', 110, 133+pos)
      doc.text('Nome e Assinatura do Recebedor', 150, 137+pos, {maxWidth: 60, align: 'center'})
      //------------------------------------------------------------------






      doc.save(`Recibo ${this.dados.reciboNumero}.pdf`)
    },


  }



}

</script>

<style scoped>


.cursor {
  cursor: pointer;
}

.button-aplicar {
  margin-top: 32px;
}

.button-imprimir {
  margin-bottom: 25px;
  margin-right: 20px;
  height: 40px;
  width: 210px;
  border: 0;
  border-radius: 50px;
  background: #8f23d6;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
}



@media (max-width: 700px) {


  .tabela {
    position: relative;
    margin-left: 15px;
    width: 90vw;
    overflow: hidden;
  }



}

</style>