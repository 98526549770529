import Vue from 'vue'
import Toasted from 'vue-toasted'
import store from '@/stores/global'

export function showError(e) {
    if (e && e.response && e.response.data) {
        Vue.toasted.global.defaultError({ msg: e.response.data })
    } else 
    if (typeof e === 'string') {
        Vue.toasted.global.defaultError({ msg: e })
    } else {
        Vue.toasted.global.defaultError({ msg: e })
    }
}

export function msg(texto, cor, tempo) {

    var tipo = ''
    if (cor == 'verde') {
        cor = 'check' 
        tipo = 'success' 
    }
    if (cor == 'amarelo') { 
        cor = 'warning' 
        tipo = 'info' 
    }
    if (cor == 'vermelho') {
        cor = 'error' 
        tipo = 'error'
    }

    Vue.use(Toasted, {
        iconPack : 'material',
        theme: "toasted-primary", 
        position: "top-right", 
        fullWidth: false
    })

    Vue.toasted.show(texto, {
        icon: cor,
        duration : tempo,
        type: tipo
    });

}


export function acessoRestrito(acesso) {

    const user = store.state.auth.user

	const existe = user.accessRules.filter(item => item == acesso)
	if (existe.length == 0) {
        msg("VOCÊ NÃO POSSUI PERMISSÃO.", "vermelho", 3000);
        throw 'fim'
    }
	// throw 'Você não tem permissão.' 

}

export function capitalizeText(text) {	

    if (!text) return text 
    
    let textTemp = text.toLowerCase()
    textTemp = textTemp.split(' ')

    var i = 0 
    while (i < textTemp.length) {
        const tempStr = textTemp[i]
        textTemp[i] = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);

        i++
    }

    textTemp = textTemp.join(' ')
    
    return textTemp;
    
}

export default { showError, msg, acessoRestrito, capitalizeText }