<template>

    <div id="page-detalhes">


        <b-button class="mt-3" variant="success" block @click="filtrar">Imprimir consolidado detalhado</b-button>
        
        <div class="tabelaReceitas" v-show="false">
            <h2>Relatório de Escala</h2>
            <b-table id="tabelaReceitas" striped hover :items="itemsReceitas" :fields="fieldsPDF"></b-table>
        </div> 

        <div class="tabelaDespesas" v-show="false">
            <h2>Relatório de Escala</h2>
            <b-table id="tabelaDespesas" striped hover :items="itemsDespesas" :fields="fieldsPDF"></b-table>
        </div> 

        <div class="tabelaConsolidacoes" v-show="false">
            <h2>Relatório de Escala</h2>
            <b-table id="tabelaConsolidacoes" striped hover :items="itemsConsolidacoes" :fields="fieldsPDFConsolidado"></b-table>
        </div> 






        <!-- MODAL FILTRO -->
        <div>
            <b-modal ref="modal-filtro" hide-footer title="" size="lg">

                <div class="row text-left col-lg-12">

                    <div class="form-group col-lg-6">
                        <label><span class="text-danger"></span> Conta</label>
                        <b-input-group>
                            <b-form-select
                            id='contas'
                            v-model="conta"
                            :options="contas"
                            ></b-form-select>
                        </b-input-group>
                    </div>
                    

                    <div class="form-group col-lg-6 sel-datas">
                        <!-- <h2>Plantões</h2> -->
                        <label class="texto-data">Data de referência</label>
                        <b-form-select id='sel-mes' v-model="selectedMes" :options="meses" @change="getLancamentos()"></b-form-select>
                        <b-form-spinbutton class="sel-ano" v-model="selectedAno" min="2015" max="2040" @change="getLancamentos()"></b-form-spinbutton>
                    </div>

                </div>


                <b-row align-h="around">
                    <b-button class="mt-3 botao-confirmar" variant="success" @click="gerarPdf">Gerar PDF</b-button>
                    <b-button class="mt-3 botao-confirmar" variant="danger" @click="$refs['modal-filtro'].hide()">Cancelar</b-button>
                </b-row>

            </b-modal>
        </div>
        <!-- FIM MODAL FILTRO -->

    </div>
  
</template>

<script>
import api from '../../services/api'
import dayjs from 'dayjs'
import {jsPDF} from 'jspdf'
import 'jspdf-autotable'

export default {
    data() {
        return {

            selectedMes: null,
            meses: [
                { value: '01', text: 'Janeiro' },
                { value: '02', text: 'Fevereiro' },
                { value: '03', text: 'Março' },
                { value: '04', text: 'Abril' },
                { value: '05', text: 'Maio' },
                { value: '06', text: 'Junho' },
                { value: '07', text: 'Julho' },
                { value: '08', text: 'Agosto' },
                { value: '09', text: 'Setembro' },
                { value: '10', text: 'Outubro' },
                { value: '11', text: 'Novembro' },
                { value: '12', text: 'Dezembro' },
            ],

            selectedAno: null,


            contas: [],
            conta: null,

            fieldsPDF: [
                {key: 'fornecedor', label: 'Fornecedor'},
                {key: 'documento', label: 'Documento'},
                {key: 'categoria', label: 'Categoria'},
                {key: 'baixaData', label: 'Pago'},
                {key: 'valor', label: 'Valor'}
            ],

            fieldsPDFConsolidado: [
                {key: 'categoria', label: 'Categoria'},
                {key: 'valor', label: 'Valor'}
            ],

            nomeSede: '',
            statusFechamento: '',
            itemsReceitas: [],
            itemsDespesas: [],
            itemsConsolidacoes: [],

            dataInicio: null,
            dataFinal: null,
            
        }
    },


    created() {
        this.selectedMes = dayjs().format('MM')
        this.selectedAno = Number(dayjs().format('YYYY'))
        
        this.getContas()
    },

    mounted() {
        this.dataInicio = dayjs().format('YYYY-MM-DD')
        this.dataFinal = dayjs().format('YYYY-MM-DD')
        this.$refs["modal-filtro"].show();
    },

    methods: {

        msgNotification(texto, tipo, tempo) {
            this.$toast.open({
                message: texto,
                type: tipo,
                position: 'top-right',
                duration: tempo
                // all of other options may go here
            })
        },

        filtrar() {
            this.$refs["modal-filtro"].show();
        },

        getContas() {

            const idSede = this.$store.state.sede.id

            api.get(`relatorios/contas/${idSede}`)
            .then((res) => {
                // console.log('resssssss', res.data)
                this.contas = res.data

                this.conta = this.contas.length > 1 ? null : this.contas[0].value
            })
            .catch((err) => {
                this.load = false;
                this.msgNotification(err, 'error', 3000)
            });
          
            
        },



        async getDados() {

            const modelo = 'consolidado-detalhado'
            const idSede = this.$store.state.sede.id
            const idConta = this.conta
            this.dataInicio = dayjs(`${this.selectedAno}-${this.selectedMes}-10`).startOf('month').format('YYYY-MM-DD')
            this.dataFinal = dayjs(`${this.selectedAno}-${this.selectedMes}-10`).endOf('month').format('YYYY-MM-DD')
          
            try {
                const url = `relatorios/${modelo}/${idSede}/${idConta}/${this.dataInicio}/${this.dataFinal}`
                const res = await api.get(url)
                
                // console.log('resssssss', res.data)
                this.nomeSede = res.data.nomeSede
                this.statusFechamento = res.data.statusFechamento
                this.itemsReceitas = res.data.receitas
                this.itemsDespesas = res.data.despesas
                this.itemsConsolidacoes = res.data.consolidacoes

                this.itemsReceitas.forEach(item => {
                    item.categoria = item.categoria.toString().toLowerCase()
                })

                this.itemsDespesas.forEach(item => {
                    item.categoria = item.categoria.toString().toLowerCase()
                })

            } catch (error) {
                this.msgNotification(error, 'error', 3000)
            }
            
        },

        getImgUrl(nomeFile) {
            var images = require.context('../../assets/', false, /\.png$/)
            return images('./' + nomeFile + ".png")
        },

        async gerarPdf() {


            if (this.conta === null) {
                this.msgNotification('Informe a conta', 'warning', 5000)   
                return
            }

            
            if (!this.dataInicio || !this.dataFinal) {
                this.msgNotification('Informe a data corretamente', 'warning', 3000)   
                return
            }

            // this.$router.push({ name: "dashboard" })


            await this.getDados()

            if (this.itemsReceitas.length == 0 && this.itemsDespesas.length == 0) {
                this.msgNotification('Não existem lançamentos neste período', 'warning', 5000)   
                return
            }
            
            this.$refs["modal-filtro"].hide();



 


            const dataInicio = dayjs(this.dataInicio).format('DD/MM/YYYY')
            const dataFinal = dayjs(this.dataFinal).format('DD/MM/YYYY')
            const auth = JSON.parse(localStorage.getItem('user'))
            const nomeConta = this.contas.filter(item => item.value == this.conta)[0].text
            
            var doc = jsPDF('p', 'mm', [210, 297]);
            // var doc = jsPDF('l', 'mm', [210, 297]);

       

            // Topo
            //------------------------------------------------------------------
            var img = new Image()
            img.src = this.getImgUrl('logoSind') 
            doc.addImage(img, 'png', 9, 8, 25, 25) 
    
            const width = doc.internal.pageSize.getWidth()
            const height = doc.internal.pageSize.getHeight()

            doc.setFontSize(11); 
            doc.text('Sindicato dos Trabalhadores em Empresas', width/2, 14, { align: 'center' })
            doc.text('Ferroviarias da Zona Sorocabana.', width/2, 19, { align: 'center' })
            doc.text(`Sede: ${this.nomeSede} - Conta: ${nomeConta}`, width/2, 24, { align: 'center' })
            doc.text(`Período: ${dataInicio} - ${dataFinal}`, width/2, 29, { align: 'center' })
            doc.text(`STATUS DE FECHAMENTO: ${this.statusFechamento}`, width/2, 35, { align: 'center' })
            

            doc.setDrawColor(0, 0, 0);
            doc.line(0, 36, 500, 36);
            //------------------------------------------------------------------
                                    



            // Tabela receitas
            //------------------------------------------------------------------
            doc.setFontSize(15);          
            doc.text(9, 47, 'Receitas ');

            doc.setFontSize(8);
            doc.autoTable({
                html: '#tabelaReceitas',
                tableHegth: height + 20,
                margin: {top: 49, left: 9}, 
                // styles: { fillColor: "#43a047" },
                styles: {overflow: 'linebreak', fontSize: 7},
                headStyles: { fillColor : [124, 95, 240] }, 
                alternateRowStyles: {fillColor : [231, 215, 252]}, 
                tableLineColor: [124, 95, 240], tableLineWidth: 0.1,



                didDrawPage: function (data){
                    // doc.text("Country List", data.settings.margin.left, 50)
                    data.settings.margin.top = 9;
                    data.settings.margin.left = 9;
                },

                theme: 'grid',
                columnStyles: {
                    // 0: {cellWidth: 60},
                    1: {cellWidth: 25},                   
                    2: {cellWidth: 35},                   
                    3: {cellWidth: 16, halign:"center"},                   
                    4: {cellWidth: 19, halign:"right"},                  
                }
            }); 

            var finalY = doc.lastAutoTable.finalY;
            doc.setFontSize(10);
            doc.text((width - 35), (finalY + 5), this.itemsConsolidacoes[1].valor);
            //------------------------------------------------------------------



            // doc.addPage() 



            // Tabela despesas
            //------------------------------------------------------------------
            doc.setFontSize(15);          
            doc.text(9, (finalY + 25), 'Despesas');

            doc.setFontSize(8);
            doc.autoTable({ 
                html: '#tabelaDespesas',
                startY: finalY + 27,
                tableHegth: height - 10,
                // margin: {top: 49, left: 9, bottom: 60}, 
                margin: {top: 49, left: 9}, 
                // styles: { fillColor: "#43a047" },
                styles: {overflow: 'linebreak', fontSize: 7},
                headStyles: {fillColor : [124, 95, 240]}, 
                alternateRowStyles: {fillColor : [231, 215, 252]}, 
                tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


                didDrawPage: function (data){
                    data.settings.margin.top = 9;
                    data.settings.margin.left = 9;
                },
                theme: 'grid',
                columnStyles: {
                    // 0: {cellWidth: 60},
                    1: {cellWidth: 25},                   
                    2: {cellWidth: 35},                   
                    3: {cellWidth: 16, halign:"center"},                   
                    4: {cellWidth: 19, halign:"right"},                   
                }
            }); 
            

            finalY = doc.lastAutoTable.finalY;
            doc.setFontSize(10);
            doc.text((width - 35), (finalY + 5), this.itemsConsolidacoes[2].valor);
            //------------------------------------------------------------------



            // doc.addPage() 



            // Tabela consolidação
            //------------------------------------------------------------------
            // doc.setFontSize(15);          
            // doc.text(90, (finalY + 70), 'Consolidação');

            doc.autoTable({ 
                html: '#tabelaConsolidacoes',
                startY: finalY + 27,
                tableWidth : 84,
                margin: {top: 49, left: 65, bottom: 60}, 
                styles: {
                    halign: 'center',
                    // cellWidth: 21
                },
                headStyles: {fillColor : [124, 95, 240]}, 
                alternateRowStyles: {fillColor : [231, 215, 252]}, 
                tableLineColor: [124, 95, 240], tableLineWidth: 0.1,


                didDrawPage: function (data){
                    data.settings.margin.top = 9;
                    data.settings.margin.left = 9;
                },
                theme: 'grid',
                columnStyles: {
                    0: {cellWidth: 50},
                    1: {cellWidth: 34, halign:"right"},                  
                }
            }); 
            //------------------------------------------------------------------




            // Rodapé
            //------------------------------------------------------------------
            doc.setFontSize(9);          
            doc.text(9, (height - 10), 'Usuário: ' + auth.nome);

            doc.setFontSize(9);
            doc.text(120, (height - 10), 'Data: ' + dayjs().format('DD/MM/YYYY'));

            doc.setFontSize(9);
            doc.text(150, (height - 10), 'Hora: ' + dayjs().format('HH:mm'));

            var pageCount = doc.internal.getNumberOfPages();
            for(var i = 0; i < pageCount; i++) { 
                doc.setPage(i); 
                let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
                doc.setFontSize(9);
                doc.text('Pág ' + pageCurrent + '/' + pageCount, 190, (height - 10));
            }
            //------------------------------------------------------------------


            doc.save('Consolidado - Categorias.pdf')
        },

    }
}
</script>

<style scoped>


.sel-datas {
    display: flex;
    flex-direction: row;
}

#sel-mes {
    width: 200px;
    margin-top: 34px;
    margin-left: -140px;
}

.sel-ano {
    width: 140px;
    margin-top: 34px;
    margin-left: 10px;
}

.texto-data {
    width: 140px;
    margin-top: 0px;
    /* margin-left: -10px; */
}

.botao-confirmar {
  width: 45%; 
  font-size: 18px;
}


</style>